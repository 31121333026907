
export default {

  methods: {
    getRiskAssessments(params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/risk-assessments`,
          params: params,
        };
        this.$http(options)
          .then(res => resolve(res))
          .catch(err => reject(err))
      })
    },

    storeRiskAssessment(obj) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData()
        bodyFormData.append('title', obj.title);
        bodyFormData.append('notes', obj.notes)
        const options = {
          method: "POST",
          url: `${process.env.VUE_APP_BASEURL}/risk-assessments`,
          data: bodyFormData,
        };
        this.$http(options)
          .then(res => resolve(res))
          .catch(err => reject(err))
      })
    },

    getRiskAssessment(id) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/risk-assessments/${id}`,
        };
        this.$http(options)
          .then(res => resolve(res))
          .catch(err => reject(err))
      })
    },

    getRiskAssessmentResponses(riskAssessmentId, params) {
      return new Promise((resolve, reject) => {

        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/risk-assessments/${riskAssessmentId}/responses`,
          params: params,
        }
        this.$http(options)
          .then(res => resolve(res))
          .catch(err => reject(err))
      })
    },

    getRiskAssessmentResponseDetails(riskAssessmentId, responseId) {
      return new Promise((resolve, reject) => {

        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/risk-assessments/${riskAssessmentId}/responses/${responseId}`,
        }
        this.$http(options)
          .then(res => resolve(res))
          .catch(err => reject(err))
      })
    },

    storeRiskAssessmentResponse(riskAssessmentId, obj) {
      console.log('Form Data', obj)
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData()
        //---------Risk----------
        if (obj.risk.id) {
          bodyFormData.append('risk_id', obj.risk.id)
        }
        else {
          bodyFormData.append('risk_name', obj.risk.name)
        }
        //------------Threat-------------
        if (obj.threat.id) {
          bodyFormData.append('threat_id', obj.threat.id)
        }
        else {
          bodyFormData.append('threat_name', obj.threat.name)
        }
        bodyFormData.append('threat_value', obj.threat.value)
        //--------------Vulnerabilities-----------------
        if (obj.vulnerabilities.addedList !== null && obj.vulnerabilities.addedList.length > 0) {
          obj.vulnerabilities.addedList.map((vulnerability, i) => {
            if (vulnerability.id !== null) {
              bodyFormData.append(`vulnerabilities[${i}][vulnerability_id]`, vulnerability.id)
              bodyFormData.append(`vulnerabilities[${i}][vulnerability_value]`, vulnerability.value)
            }
            else if (vulnerability.name !== null) {
              bodyFormData.append(`vulnerabilities[${i}][vulnerability_name]`, vulnerability.name)
              bodyFormData.append(`vulnerabilities[${i}][is_new]`, true)
              bodyFormData.append(`vulnerabilities[${i}][vulnerability_value]`, vulnerability.value)
            }

          })
        }
        //---------------Risk Statement--------------------
        if (obj.riskStatement !== null) {
          bodyFormData.append('risk_statement', obj.riskStatement)
        }
        //---------------Impact Value----------------------
        if (obj.impactValue !== null) {
          bodyFormData.append('impact_score', obj.impactValue)
        }
        //---------------Likelihood Value----------------------
        if (obj.likelihoodValue !== null) {
          bodyFormData.append('likelihood_value', obj.likelihoodValue)
        }
        //---------------Residual Impact Value----------------------
        if (obj.residualImpactValue !== null) {
          bodyFormData.append('residual_impact_score', obj.residualImpactValue)
        }
        //---------------Residual Likelihood Value----------------------
        if (obj.residualLikelihoodValue !== null) {
          bodyFormData.append('residual_likelihood_value', obj.residualLikelihoodValue)
        }
        const options = {
          method: "POST",
          url: `${process.env.VUE_APP_BASEURL}/risk-assessments/${riskAssessmentId}/responses`,
          data: bodyFormData,
        };
        this.$http(options)
          .then(res => resolve(res))
          .catch(err => reject(err))
      })
    },

    mapColorToValue() {
      if (this.sliderValue <= 11) {
        return "#28C76F";
      } else if (this.sliderValue > 11 && this.sliderValue <= 17) {
        return "#00CFE8";
      } else if (this.sliderValue > 17 && this.sliderValue <= 35) {
        return "#7267f0";
      } else if (this.sliderValue > 35 && this.sliderValue <= 74) {
        return "#FF9F43";
      } else if (this.sliderValue > 74 && this.sliderValue <= 100) {
        return "#EA5455";
      } else {
        return "#28C76F";
      }
    },

    mapTextClassToValue() {
      if (this.sliderValue <= 11) {
        return "text-success";
      } else if (this.sliderValue > 11 && this.sliderValue <= 17) {
        return "text-info";
      } else if (this.sliderValue > 17 && this.sliderValue <= 35) {
        return "text-primary";
      } else if (this.sliderValue > 35 && this.sliderValue <= 74) {
        return "text-warning";
      } else if (this.sliderValue > 74 && this.sliderValue <= 100) {
        return "text-danger";
      } else {
        return "text-success";
      }
    },

    mapValueName() {
      if (this.sliderValue <= 11) {
        return "Very Low";
      } else if (this.sliderValue > 11 && this.sliderValue <= 17) {
        return "Low";
      } else if (this.sliderValue > 17 && this.sliderValue <= 35) {
        return "Medium";
      } else if (this.sliderValue > 35 && this.sliderValue <= 74) {
        return "High";
      } else if (this.sliderValue > 74 && this.sliderValue <= 100) {
        return "Very High";
      } else {
        return "Very Low";
      }
    },

    updateRiskAssessmentResponse(riskAssessmentId, responseId, obj) {
      console.log('Form Data', obj)
      return new Promise((resolve, reject) => {

        const bodyFormData = new FormData()

        if (obj.risk.id) {
          bodyFormData.append('risk_id', obj.risk.id)
        }
        else {
          bodyFormData.append('risk_name', obj.risk.name)
        }
        
        //------------Threat-------------
        if (obj.threat.id) {
          bodyFormData.append('threat_id', obj.threat.id)
        }
        else {
          bodyFormData.append('threat_name', obj.threat.name)
        }
        bodyFormData.append('threat_value', obj.threat.value)

        //--------------Vulnerabilities-----------------
        if (obj.vulnerabilities.addedList !== null && obj.vulnerabilities.addedList.length > 0) {
          obj.vulnerabilities.addedList.map((vulnerability, i) => {
            if (vulnerability.id !== null) {
              bodyFormData.append(`vulnerabilities[${i}][vulnerability_id]`, vulnerability.id)
              bodyFormData.append(`vulnerabilities[${i}][vulnerability_value]`, vulnerability.value)
            }
            else if (vulnerability.name !== null) {
              bodyFormData.append(`vulnerabilities[${i}][vulnerability_name]`, vulnerability.name)
              bodyFormData.append(`vulnerabilities[${i}][is_new]`, true)
              bodyFormData.append(`vulnerabilities[${i}][vulnerability_value]`, vulnerability.value)
            }

          })
        }

        //---------------Risk Statement--------------------
        if (obj.riskStatement !== null) {
          bodyFormData.append('risk_statement', obj.riskStatement)
        }
        //---------------Impact Value----------------------
        if (obj.impactValue !== null) {
          bodyFormData.append('impact_score', obj.impactValue)
        }
        //---------------Likelihood Value----------------------
        if (obj.likelihoodValue !== null) {
          bodyFormData.append('likelihood_value', obj.likelihoodValue)
        }
        //---------------Residual Impact Value----------------------
        if (obj.residualImpactValue !== null) {
          bodyFormData.append('residual_impact_score', obj.residualImpactValue)
        }
        //---------------Residual Likelihood Value----------------------
        if (obj.residualLikelihoodValue !== null) {
          bodyFormData.append('residual_likelihood_value', obj.residualLikelihoodValue)
        }
        const options = {
          method: "POST",
          url: `${process.env.VUE_APP_BASEURL}/risk-assessments/${riskAssessmentId}/responses/${responseId}`,
          data: bodyFormData,
        };
        this.$http(options)
          .then(res => resolve(res))
          .catch(err => reject(err))
      })
    }
  }
}