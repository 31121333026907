<template>
  <div>
    <b-modal ref="my-modal" hide-footer title="Are you sure?" size="lg">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="mt-2 mr-1"
        variant="outline-secondary"
        @click="hideModal"
      >
        Cancel
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="mt-2"
        variant="danger"
        @click="deleteLegal"
      >
        Delete
      </b-button>
    </b-modal>
    <b-sidebar
      id="sidebar-applicable"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      no-close-on-backdrop
    >
      <b-overlay
        opacity="0.17"
        blur="1rem"
        :show="showOverlay"
        rounded="md"
        variant="secondary"
      >
        <div class="card" style="box-shadow: none !important">
          <div class="card-header border-bottom d-flex">
            <div
              style="width: 70%"
              class="d-flex flex-column justify-content-center align-items-start"
            >
              <h4 class="font-weight-bolder">
                <span class="align-middle">Applicability Review</span>
              </h4>
              <p class="card-subtitle text-muted pt-75"></p>
            </div>

            <div class="d-flex justify-content-end align-items-center">
              <b-button
                size="sm"
                @click="closeApplicable()"
                variant="outline-secondary"
                ><feather-icon size="24" icon="XIcon"
              /></b-button>
            </div>
          </div>

          <div class="p-1">
            <b-form-group label="Applicability" label-for="defaultLabel">
              <template slot="label">
                Applicability <span class="text-danger">*</span>
              </template>
              <select
                name=""
                id=""
                class="form-control"
                v-model="applicability"
              >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </b-form-group>
            <b-form-group label="Applicability Note" label-for="defaultLabel">
              <template slot="label">
                Applicability Note <span class="text-danger">*</span>
              </template>
              <b-form-textarea
                id="textarea-default"
                placeholder="Enter Note"
                rows="3"
                v-model="applicability_note"
              />
            </b-form-group>
            <b-form-group label="Assessment Date" label-for="defaultLabel">
              <template slot="label">
                Applicability Date <span class="text-danger">*</span>
              </template>
              <flat-pickr v-model="assessmentDate" class="form-control" />
            </b-form-group>
            <b-form-group label="Interested Parties">
              <div class="d-flex">
                <v-select
                  taggable
                  v-model="partyname"
                  label="text"
                  :options="parties_suggestion"
                  @search="getIntrestedParties"
                  @option:selected="AddParties(partyname)"
                  autocomplete
                  state="errors.length > 0 ? false:null"
                  style="width: 100%"
                />
                <!-- <input type="text" class="form-control" v-model="partyname">
                <button class="btn btn-sm btn-primary ml-1" @click="AddParties(partyname)" :disabled="partyname == ''">+</button> -->
              </div>
              <label for="" class="mt-1"
                ><span v-if="parties.length > 0">Added Parties</span></label
              >
              <ul style="padding: 15px !important">
                <li v-for="(party, index) in parties">
                  <span
                    class="d-flex align-items-center justify-content-between"
                  >
                    <span>{{ party }}</span>
                    <span
                      @click="removeParties(index)"
                      style="cursor: pointer"
                      class="text-danger"
                      >X</span
                    >
                  </span>
                </li>
              </ul>
            </b-form-group>

            <button class="btn btn-primary mr-1" @click="AddReview">
              Add Review
            </button>
            <button class="btn btn-secondary" @click="closeApplicable">
              Cancel
            </button>
          </div>
        </div>
      </b-overlay>
    </b-sidebar>
    <b-sidebar
      id="sidebar-applicable-edit"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      no-close-on-backdrop
    >
      <b-overlay
        opacity="0.17"
        blur="1rem"
        :show="showOverlay"
        rounded="md"
        variant="secondary"
      >
        <div class="card" style="box-shadow: none !important">
          <div class="card-header border-bottom d-flex">
            <div
              style="width: 70%"
              class="d-flex flex-column justify-content-center align-items-start"
            >
              <h4 class="font-weight-bolder">
                <span class="align-middle">Edit Applicability Review</span>
              </h4>
              <p class="card-subtitle text-muted pt-75"></p>
            </div>

            <div class="d-flex justify-content-end align-items-center">
              <b-button
                size="sm"
                @click="closeEditLegalAssessment()"
                variant="outline-secondary"
                ><feather-icon size="24" icon="XIcon"
              /></b-button>
            </div>
          </div>

          <div class="p-1">
            <b-form-group label="Applicability" label-for="defaultLabel">
              <template slot="label">
                Applicability <span class="text-danger">*</span>
              </template>
              <select
                name=""
                id=""
                class="form-control"
                v-model="applicability"
              >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </b-form-group>
            <b-form-group label="Applicability Note" label-for="defaultLabel">
              <template slot="label">
                Applicability Note <span class="text-danger">*</span>
              </template>
              <b-form-textarea
                id="textarea-default"
                placeholder="Enter Note"
                rows="3"
                v-model="applicability_note"
              />
            </b-form-group>
            <b-form-group label="Assessment Date" label-for="defaultLabel">
              <template slot="label">
                Applicability Date <span class="text-danger">*</span>
              </template>
              <flat-pickr v-model="assessmentDate" class="form-control" />
            </b-form-group>
            <b-form-group label="Interested Parties">
              <div class="d-flex">
                <v-select
                  taggable
                  v-model="partyname"
                  label="text"
                  :options="parties_suggestion"
                  @search="getIntrestedParties"
                  @option:selected="AddParties(partyname)"
                  autocomplete
                  state="errors.length > 0 ? false:null"
                  style="width: 100%"
                />
                <!-- <input type="text" class="form-control" v-model="partyname">
                <button class="btn btn-sm btn-primary ml-1" @click="AddParties(partyname)" :disabled="partyname == ''">+</button> -->
              </div>
              <label for="" class="mt-1"
                ><span v-if="parties.length > 0">Added Parties</span></label
              >
              <ul style="padding: 15px !important">
                <li v-for="(party, index) in parties">
                  <span
                    class="d-flex align-items-center justify-content-between"
                  >
                    <span>{{ party }}</span>
                    <span
                      @click="removeParties(index)"
                      style="cursor: pointer"
                      class="text-danger"
                      >X</span
                    >
                  </span>
                </li>
              </ul>
            </b-form-group>

            <button class="btn btn-primary mr-1" @click="updateLegalAssessment">
              Update Review
            </button>
            <button class="btn btn-secondary" @click="closeEditLegalAssessment">
              Cancel
            </button>
          </div>
        </div>
      </b-overlay>
    </b-sidebar>
    <b-sidebar
      id="sidebar-backdrop"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
    >
      <b-overlay
        opacity="0.17"
        blur="1rem"
        :show="showOverlay"
        rounded="md"
        variant="secondary"
      >
        <div class="card" style="box-shadow: none !important">
          <div class="card-header border-bottom d-flex">
            <div
              style="width: 70%"
              class="d-flex flex-column justify-content-center align-items-start"
            >
              <h4 class="font-weight-bolder">
                <span class="align-middle">Add new Legal Requirement</span>
              </h4>
              <p class="card-subtitle text-muted pt-75"></p>
            </div>

            <div class="d-flex justify-content-end align-items-center">
              <b-button
                size="sm"
                @click="closeSidebar()"
                variant="outline-secondary"
                ><feather-icon size="24" icon="XIcon"
              /></b-button>
            </div>
          </div>
          <div class="p-1">
            <b-form-group label="Title" label-for="defaultLabel">
              <b-form-input
                id="defaultLabel"
                placeholder="Title"
                v-model="title"
              />
            </b-form-group>
            <label for="textarea-default">Requirement</label>
            <b-form-textarea
              id="textarea-default"
              placeholder="Requirement"
              rows="3"
              v-model="content"
            />

            <!-- <label for="textarea-default">Effective Date</label>
            <flat-pickr v-model="dateDefault" class="form-control" /> -->
            <div class="row mt-1">
              <div class="col-6">
                <label for="">Internal P.O.C</label>
                <select
                  name=""
                  id=""
                  class="form-control"
                  v-model="internalpoc"
                >
                  <option
                    :value="user.user_id"
                    v-for="user in this.userslist.data"
                    :key="user"
                  >
                    {{ user.firstname }}
                  </option>
                </select>
                <!-- {{userslist}} -->
              </div>
              <div class="col-6">
                <label for="">Primary Type</label>
                <select name="" id="" class="form-control" v-model="type">
                  <option value="" disabled selected>Select a Type</option>
                  <option value="legal">Legal</option>
                  <option value="regulatory">Regulatory</option>
                  <option value="contractual">Contractual</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <b-form-group label="Authority" label-for="defaultLabel">
                  <b-form-input
                    id="defaultLabel"
                    placeholder="Enter Authority"
                    v-model="authority"
                  />
                </b-form-group>
              </div>
            </div>
            <!-- <label for="textarea-default">Next assessment Date</label>
            <flat-pickr v-model="dateDefault" class="form-control mb-1" /> -->
            <!-- <b-form-group label="Country" label-for="defaultLabel">
              <b-form-input
                id="defaultLabel"
                placeholder="Country"
                v-model="country"
              />
            </b-form-group> -->
            <button class="btn btn-primary mr-1" @click="createLegalRegister">
              Create
            </button>
            <button class="btn btn-secondary" @click="closeSidebar">
              Cancel
            </button>
          </div>
        </div>
      </b-overlay>
    </b-sidebar>
    <b-sidebar
      id="sidebar-edit"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
    >
      <b-overlay
        opacity="0.17"
        blur="1rem"
        :show="showOverlay"
        rounded="md"
        variant="secondary"
      >
        <div class="card" style="box-shadow: none !important">
          <div class="card-header border-bottom d-flex">
            <div
              style="width: 70%"
              class="d-flex flex-column justify-content-center align-items-start"
            >
              <h4 class="font-weight-bolder">
                <span class="align-middle">Update Legal Requirement</span>
              </h4>
              <p class="card-subtitle text-muted pt-75"></p>
            </div>

            <div class="d-flex justify-content-end align-items-center">
              <b-button
                size="sm"
                @click="closeEditLegal()"
                variant="outline-secondary"
                ><feather-icon size="24" icon="XIcon"
              /></b-button>
            </div>
          </div>

          <div class="p-1">
            <b-form-group label="Title" label-for="defaultLabel">
              <template slot="label">
                Title <span class="text-danger">*</span>
              </template>
              <b-form-input
                id="defaultLabel"
                placeholder="Title"
                v-model="title"
              />
            </b-form-group>

            <b-form-group label="Title" label-for="defaultLabel">
              <template slot="label">
                Requirement <span class="text-danger">*</span>
              </template>
              <b-form-textarea
                id="textarea-default"
                placeholder="Requirement"
                rows="3"
                v-model="content"
              />
            </b-form-group>

            <!-- <label for="textarea-default">Effective Date</label>
            <flat-pickr v-model="dateDefault" class="form-control" /> -->
            <div class="row mt-1">
              <div class="col-6">
                <b-form-group label="Internal P.O.C" label-for="defaultLabel">
                  <template slot="label">
                    Internal P.O.C <span class="text-danger">*</span>
                  </template>
                  <select
                    name=""
                    id=""
                    class="form-control"
                    v-model="internalpoc"
                  >
                    <option
                      :value="user.user_id"
                      v-for="user in this.userslist.data"
                      :key="user"
                    >
                      {{ user.firstname }}
                    </option>
                  </select>
                </b-form-group>
                <!-- {{userslist}} -->
              </div>
              <div class="col-6">
                <b-form-group label="Type" label-for="defaultLabel">
                  <template slot="label">
                    Primary Type <span class="text-danger">*</span>
                  </template>
                  <select name="" id="" class="form-control" v-model="type">
                    <option value="" disabled selected>Select a Type</option>
                    <option value="legal">Legal</option>
                    <option value="regulatory">Regulatory</option>
                    <option value="contractual">Contractual</option>
                  </select>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <b-form-group label="Authority" label-for="defaultLabel">
                  <b-form-input
                    id="defaultLabel"
                    placeholder="Enter Authority"
                    v-model="authority"
                  />
                </b-form-group>
              </div>
            </div>
            <!-- <label for="textarea-default">Next assessment Date</label>
            <flat-pickr v-model="dateDefault" class="form-control mb-1" /> -->
            <!-- <b-form-group label="Country" label-for="defaultLabel">
              <b-form-input
                id="defaultLabel"
                placeholder="Country"
                v-model="country"
              />
            </b-form-group> -->
            <button class="btn btn-primary mr-1" @click="updateLegalRegister">
              Update Requirement
            </button>
            <button class="btn btn-secondary" @click="closeEditLegal">
              Cancel
            </button>
          </div>
        </div>
      </b-overlay>
    </b-sidebar>

    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="secondary"
    >
      <div class="card mb-1">
        <!-- {{registerDetails}} -->
        <div class="px-2 pt-1">
          <!-- <div class="row">
            <div class="col-7"> -->
          <h3 class="text-capitalize d-flex aligin-items-center">
            <b-badge style="margin-right: 5px">{{
              registerDetails.legal_register_number
            }}</b-badge>
            <span>
              {{ registerDetails.title }}
            </span>
          </h3>
          <!-- </div> -->
          <!-- <div
              class="col-5 d-flex justify-content-end align-items-center"
              v-if="registerDetails.authority"
            >
              <feather-icon icon="ShieldIcon" class="mr-50" />
              <span
                style="font-size: 15px"
                class=""
                v-b-tooltip.hover.v-dark
                title="Authority"
              >
                {{ registerDetails.authority }}
              </span>
            </div> -->
          <!-- </div> -->

          <div class="info-section">
            <p v-if="registerDetails.requirement">
              <strong class=""
                ><feather-icon icon="ClipboardIcon" class="mr-50" /> Requirement
                :</strong
              >
              <span v-if="registerDetails.requirement === 'null' || registerDetails.requirement === ''"> Nill</span>
              <span v-else> {{ registerDetails.requirement }}</span>
            </p>
            <p
              style="margin-bottom: 0.5rem !important"
              v-if="registerDetails.authority"
            >
              <strong class=""
                ><feather-icon icon="ShieldIcon" class="mr-50" /> Authority
                :</strong
              >
              {{ registerDetails.authority }}
            </p>
          </div>
        </div>
        <div class="card-body" style="padding: .5rem 1.5rem;">
          <div class="row info-section">
            <div class="col-3">
              <p class="d-flex align-items-center">
                <span class="">
                  <feather-icon icon="TagIcon" class="mr-50" />
                  <strong class="mr-1">Type :</strong>
                </span>
                <b-badge
                  :variant="
                    registerDetails.type == 'legal'
                      ? 'primary'
                      : registerDetails.type == 'regulatory'
                      ? 'warning'
                      : registerDetails.type == 'contractual'
                      ? 'info'
                      : ''
                  "
                  class="text-capitalize"
                >
                  {{ registerDetails.type }}
                </b-badge>
              </p>
            </div>
            <div class="col-3">
              <p class="d-flex align-items-center">
                <feather-icon icon="UserIcon" class="mr-50" />
                <strong class="mr-1">Internal P.O.C :</strong>
                <template
                  v-if="
                    registerDetails.internal_poc_user &&
                    registerDetails.internal_poc_user != null
                  "
                >
                  <span class="mr-25">{{
                    registerDetails.internal_poc_user.firstname
                  }}</span>
                  <span>{{ registerDetails.internal_poc_user.lastname }}</span>
                </template>
                <b-badge v-else variant="danger">Deleted User</b-badge>
              </p>
            </div>
            <div class="col-3">
              <p class="d-flex align-items-center">
                <feather-icon icon="MapPinIcon" class="mr-50" />
                <strong class="mr-1">Country :</strong>
                <b-badge variant="primary" v-if="registerDetails.country">
                  {{ registerDetails.country }}
                </b-badge>
                <b-badge variant="primary" v-else> Global </b-badge>
              </p>
            </div>
            <div class="col-3" v-if="registerDetails.industry">
              <p class="d-flex align-items-center">
                <feather-icon icon="BriefcaseIcon" class="mr-50" />
                <strong class="mr-1">Industry :</strong>
                {{ registerDetails.industry }}
              </p>
            </div>
          </div>

          <div class="d-flex justify-content-end mt-1">
            <button
              class="btn btn-success mr-1 btn-sm"
              @click="ApplicabilityReview(registerDetails._id)"
            >
              Applicability Review
            </button>
            <b-button
              @click="openEditLegal(registerDetails._id)"
              variant="primary"
              class="btn-sm"
            >
              <feather-icon icon="EditIcon" class="mr-50" /> Edit
            </b-button>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <!-- {{legalid}} -->
          <!-- {{registerDetails.type}} -->
        </div>
        <div class="card-body">
          <!-- <b-button @click="openSidebar" class="mb-75" variant="success"
            ><feather-icon class="mr-75" icon="PlusIcon" />New Legal
            Register</b-button
          > -->
          <h4>Review History</h4>
          <!-- {{ assessmentList }} -->
          <table role="table" class="table table-hover">
            <thead role="rowgroup">
              <tr role="row">
                <th role="columnheader" scope="col">Applicability</th>
                <th role="columnheader" scope="col">Applicability Note</th>
                <th role="columnheader" scope="col">Assessed User</th>
                <th role="columnheader" scope="col">Assessment date</th>
                <th role="columnheader" scope="col">Interested Parties</th>
                <th role="columnheader" scope="col">Action</th>
              </tr>
            </thead>
            <tbody role="rowgroup">
              <!-- {{this.legallist}} -->
              <tr
                v-for="assess in this.assessmentList"
                :key="assess._id"
                role="row"
                class="cursor-pointer"
              >
                <td aria-colindex="2" role="cell" style="width: 200px">
                  <b-badge variant="primary">
                    {{ assess.applicability == 1 ? "Yes" : "No" }}
                  </b-badge>
                </td>

                <td aria-colindex="2" role="cell" style="width: 200px">
                  {{ assess.applicability_note }}
                </td>

                <td aria-colindex="2" role="cell" style="padding-right: 0">
                  <template
                    v-if="assess.assessed_user && assess.assessed_user != null"
                  >
                    {{
                      assess.assessed_user ? assess.assessed_user.firstname : ""
                    }}
                    {{
                      assess.assessed_user ? assess.assessed_user.lastname : ""
                    }}
                  </template>
                  <b-badge v-else variant="danger">Deleted User</b-badge>
                </td>

                <td aria-colindex="2" role="cell" style="padding-right: 0">
                  {{ assess.assessment_date | moment }}
                </td>
                <td aria-colindex="2" role="cell" style="padding-right: 0">
                  <!-- {{ assess.intrested_parties }} -->
                  <b-badge
                    variant="primary"
                    v-for="ip in assess.intrested_parties"
                    class="truncate-badge"
                    v-b-tooltip.hover.top.v-secondary
                    :title="ip"
                    >{{ ip }}</b-badge
                  >
                </td>

                <td aria-colindex="2" role="cell" style="padding-right: 0">
                  <!-- <button
                    class="btn btn-warning mr-1 btn-sm"
                    @click="viewLegalRegister(legal._id)"
                  >
                    View
                  </button> -->
                  <button
                    class="btn btn-primary mr-1 btn-sm"
                    @click="openEditLegalAssessment(assess._id)"
                  >
                    Edit
                  </button>

                  <button
                    class="btn btn-danger btn-sm"
                    @click="deleteLegalAssessment(assess._id)"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="mt-3">
            <div class="row">
              <div
                class="d-flex align-items-center justify-content-center col-12"
              >
                <b-pagination
                  v-model="filters.page"
                  :per-page="pagination.perPage"
                  :total-rows="pagination.rows"
                  aria-controls="my-table"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BPagination,
  BButton,
  BSidebar,
  BOverlay,
  BAvatar,
  BCollapse,
  BBadge,
  BModal,
  BCol,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  VBToggle,
  VBTooltip,
} from "bootstrap-vue";
import RiskAssessmentMixins from "@//mixins/RiskAssessmentMixins";
import ResponseMixins from "@//mixins/ResponseMixins";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import DonutBar from "@/components/DonutBar.vue";
import UtilsMixins from "../../mixins/UtilsMixins";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import vSelect from "vue-select";
import BackButton from "@/components/BackButton.vue";
export default {
  components: {
    BPagination,
    BButton,
    BSidebar,
    BFormInput,
    BOverlay,
    BBadge,
    BAvatar,
    BCollapse,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCol,
    BForm,
    BFormGroup,
    BFormTextarea,
    DonutBar,
    flatPickr,
    ToastificationContent,
    vSelect,
    BackButton,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      showOverlay: false,
      pagination: {
        lastPage: null,
        perPage: 10,
        rows: 1,
      },
      filters: {
        page: 1,
      },
      newRiskAssessment: {
        title: null,
        notes: null,
      },
      assessments: null,
      dateDefault: null,
      title: "",
      content: "",
      authority: "",
      internalpoc: "",
      type: "",
      country: "",
      userslist: [],
      legallist: [],
      legalid: "",
      legaldetails: [],
      authorities: [],
      registerDetails: [],
      assessmentList: [],
      applicability: "",
      applicability_note: "",
      assessmentDate: "",
      legalassessmentid: "",
      partyname: "",
      parties: [],
      parties_suggestion: [],
    };
  },
  mixins: [ResponseMixins, RiskAssessmentMixins, UtilsMixins],
  watch: {
    filters: {
      handler: function (newValue) {
        this.getRiskAssessmentsAndSetData(newValue);
      },
      deep: true,
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },
  mounted() {
    this.load();
    // this.getAuthority();
    this.legalid = this.$route.params.id;
    this.getLegalRegisterDetails(this.legalid);
    this.listAssessments(this.legalid);
  },
  methods: {
    getIntrestedParties() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/legal-registers/intrested-parties`,
      };
      this.$http(options)
        .then((res) => {
          // this.countries = res.data.data.data;
          this.parties_suggestion = res.data.data;
          console.log(JSON.stringify(res.data) + "Intrested parties");

          // console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    AddParties(party) {
      if (this.partyname.trim() !== "" && !this.parties.includes(party)) {
        this.parties.push(party);
        this.partyname = "";
      }
    },
    removeParties(index) {
      this.parties.pop(index);
    },
    legalAssessmentDetails(id) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          `/legal-registers/${this.legalid}/assessments/${id}`,
      };
      this.$http(options)
        .then((res) => {
          this.applicability = res.data.data.applicability;
          this.applicability_note = res.data.data.applicability_note;
          this.assessmentDate = res.data.data.assessment_date;
          this.parties = res.data.data.intrested_parties
            ? res.data.data.intrested_parties
            : [];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteLegalAssessment(id) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          `/legal-registers/${this.legalid}/assessments/${id}`,
      };
      this.$http(options)
        .then((res) => {
          this.listAssessments(this.legalid);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateLegalAssessment() {
      const data = {
        applicability: this.applicability,
        applicability_note: this.applicability_note,
        assessment_date: this.assessmentDate,
        intrested_parties: this.parties,
      };
      const options = {
        method: "PUT",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          `/legal-registers/${this.legalid}/assessments/${this.legalassessmentid}`,
      };
      this.$http(options)
        .then((res) => {
          //   this.sidebar_bulkupdate = false;
          this.closeEditLegalAssessment();
          this.listAssessments(this.legalid);
          this.applicability = "";
          this.applicability_note = "";
          this.assessmentDate = "";

          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openEditLegalAssessment(id) {
      this.legalassessmentid = id;
      this.$root.$emit("bv::toggle::collapse", "sidebar-applicable-edit");
      this.legalAssessmentDetails(this.legalassessmentid);
    },
    closeEditLegalAssessment() {
      this.$root.$emit("bv::toggle::collapse", "sidebar-applicable-edit");
    },
    AddReview() {
      const data = {
        applicability: this.applicability,
        applicability_note: this.applicability_note,
        assessment_date: this.assessmentDate,
        intrested_parties: this.parties,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          `/legal-registers/${this.legalid}/assessments`,
      };
      this.$http(options)
        .then((res) => {
          //   this.sidebar_bulkupdate = false;
          this.closeApplicable();
          this.listAssessments(this.legalid);
          this.applicability = "";
          this.applicability_note = "";
          this.assessmentDate = "";

          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ApplicabilityReview(legalid) {
      this.legalid = legalid;
      this.$root.$emit("bv::toggle::collapse", "sidebar-applicable");
      this.applicability = "";
      this.applicability_note = "";
      this.assessmentDate = "";
      this.parties = [];
    },
    closeApplicable() {
      this.$root.$emit("bv::toggle::collapse", "sidebar-applicable");
      this.applicability = "";
      this.applicability_note = "";
      this.assessmentDate = "";
      this.parties = [];
    },
    listAssessments(legalid) {
      this.showOverlay = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          `/legal-registers/${legalid}/assessments?page=&limit=&type=&search=&sort&order`,
      };
      this.$http(options)
        .then((res) => {
          this.assessmentList = res.data.data.data;
          this.pagination.lastPage = res.data.data.last_page;
          this.pagination.perPage = res.data.data.per_page;
          this.pagination.rows = res.data.data.last_page * 10;
          this.showOverlay = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLegalRegisterDetails(legalid) {
      this.showOverlay = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/legal-registers/${legalid}`,
      };
      this.$http(options)
        .then((res) => {
          this.registerDetails = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getAuthority() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSFURL + `/authorities`,
      };
      this.$http(options)
        .then((res) => {
          this.authorities = res.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    load() {
      //   this.getRiskAssessmentsAndSetData(this.filters);

      this.getUsers();
      this.getLegalList();
    },
    createLegalRegister() {
      const data = {
        title: this.title,
        requirement: this.content,
        authority: this.authority,
        internal_poc_user_id: this.internalpoc,
        type: this.type,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/legal-registers`,
      };
      this.$http(options)
        .then((res) => {
          //   this.sidebar_bulkupdate = false;
          this.closeSidebar();
          this.getLegalList();
          this.title = "";
          this.content = "";
          this.dateDefault = "";
          this.authority = "";
          this.internalpoc = "";
          this.type = "";
          this.country = "";
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateLegalRegister() {
      const data = {
        title: this.title,
        requirement: this.content,
        effective_from: this.dateDefault,
        authority: this.authority,
        internal_poc_user_id: this.internalpoc,
        type: this.type,
        country: this.country,
      };
      const options = {
        method: "PUT",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/legal-registers/${this.legalid}`,
      };
      this.$http(options)
        .then((res) => {
          //   this.sidebar_bulkupdate = false;
          this.closeEditLegal();
          this.getLegalRegisterDetails(this.legalid);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUsers() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/users?dont_paginate=true`,
      };
      this.$http(options)
        .then((res) => {
          this.userslist = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLegalList() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/legal-registers`,
      };
      this.$http(options)
        .then((res) => {
          this.legallist = res.data.data.data;
          this.pagination.lastPage = res.data.data.last_page;
          this.pagination.perPage = res.data.data.per_page;
          this.pagination.rows = res.data.data.last_page * 10;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLegalDetails() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/legal-registers/${this.legalid}`,
      };
      this.$http(options)
        .then((res) => {
          this.legaldetails = res.data.data;
          this.title = this.legaldetails.title;
          this.content = this.legaldetails.requirement;
          this.dateDefault = this.legaldetails.effective_from;
          this.authority = this.legaldetails.authority;
          this.internalpoc = this.legaldetails.internal_poc_user_id;
          this.type = this.legaldetails.type;
          this.country = this.legaldetails.country;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteLegal() {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/legal-registers/${this.legalid}`,
      };
      this.$http(options)
        .then((res) => {
          this.getLegalList();
          this.hideModal();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openEditLegal(id) {
      this.legalid = id;
      this.$root.$emit("bv::toggle::collapse", "sidebar-edit");
      this.getLegalDetails();
    },
    closeEditLegal() {
      this.$root.$emit("bv::toggle::collapse", "sidebar-edit");
    },

    handleRowClick(assessmentId) {
      this.$router.push(`/riskassessment/${assessmentId}/risks`);
    },

    getRiskAssessmentsAndSetData(params) {
      this.getRiskAssessments(params)
        .then((res) => {
          this.assessments = res.data.data.data;
          this.pagination.lastPage = res.data.data.last_page;
          this.pagination.perPage = res.data.data.per_page;
          this.pagination.rows = res.data.data.last_page * 10;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },

    handleNewRiskAssessmentModalSubmit() {
      this.storeRiskAssessment({
        title: this.newRiskAssessment.title,
        notes: this.newRiskAssessment.notes,
      })
        .then((res) => {
          this.load();
          this.handleResponse(res);
          // console.log("created", res);
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.hideModal();
        });
    },

    handleNewRiskAssessmentClick() {
      this.newRiskAssessment.title = null;
      this.newRiskAssessment.notes = null;
      this.showModal();
    },

    hideModal() {
      this.$refs["my-modal"].hide();
    },
    showModal(id) {
      this.legalid = id;
      this.$refs["my-modal"].show();
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
    openSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
  },
};
</script>

<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem !important;
}
</style>

<style lang="css" scoped>
.table td,
.table th {
  width: 10%;
  text-transform: capitalize;
}
.truncate-badge {
  /* Style to truncate text with ellipsis */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px; /* Adjust this value as per your requirement */
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
